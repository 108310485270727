import config_ofc from './config-ofc';
import config_onair_integ from './config-onair_integ';
import config_onair_es from './config-onair_es';
import config_onair from './config-onair';
import config_genae from './config-genae';
import config_accrosport from './config-accrosport';
import config_atomfitness from './config-atomfitness';
import config_oriasport from './config-oriasport';

export default function getConfig() {
	if( window.location.host.startsWith('pass-onair-integ.masalledesport') ) {
		return config_onair_integ;
	}

	if( window.location.host.startsWith('pass.atom-fitnessbeziers') ) {
		return config_atomfitness;
	}

	if ( window.location.host.startsWith('pass.onair-fitness.es') ) {
		return config_onair_es;
	}
	if ( window.location.host.startsWith('pass.onair-fitness.') ) {
		return config_onair;
	}

	if ( window.location.host.startsWith('pass.onefitnessclub.') ) {
		return config_ofc;
	}

	if ( window.location.host.startsWith('pass.genaeclub.') ) {
		return config_genae;
	}

	if ( window.location.host.startsWith('pass.accrosport.') ) {
		return config_accrosport;
	}

	if ( window.location.host.startsWith('pass.oria-sport.') ) {
		return config_oriasport;
	}

	throw new Error("No config for host " + window.location.host);
}
