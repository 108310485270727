export default {
	code: 'onair.fr',
	appClassName: 'ONAIR',
	analytics: {
		gtag: 'G-YB9C0QF43J',
		gtm: 'GTM-5JFTM9M7'
	},
	favicon: '/onair-favicon.png',
	title: "Je m'abonne | ON AIR Fitness",
	companyName: "ON AIR Fitness",
	website: 'https://onair-fitness.fr',
	notifyChannel: 'onair-fitness',
	header: {
		title: "Je m'abonne",
		logo: "/onair-logo--colored.jpeg"
	},
	footer: {
		logo: "/onair-logo--white.png",
		cgv: {
			link : 'https://onair-fitness.fr/cgv/',
			text: 'CGV'
		},
	},
	promoCode: {
		label: "Vous avez un code promo ?",
		confirmLabel: "Valider",
		verifyingLabel: "Vérification...",
		placeholder: 'code promo',
	},
	payByCard: "STRIPE",
	paymentReinsurances: [
		"Paiement sécurisé",
		"Meilleure offre garantie",
		"14 jours pour changer d’avis avant le début de l’entrainement",
	],
	choice: {
		selectLabel: 'Sélectionner',
		selectedLabel: 'Sélectionné',
		mandatoryLabel: "Obligatoire",
		nameColor: '#AE1D22',
		border: 'lightgray',
		border_selected: 'black',
		background: 'white',
		footerBg: '#ededed',
		footerBg_selected: '#F7F7F7',
		checkboxSelected: '#AE1D22'
	},
	errorCode: {
		"api.error.sale.contact-invalid-data": "L’adresse e-mail fournie est déjà enregistrée dans notre système. Veuillez utiliser une autre adresse e-mail ou contacter le club.",
		default: "Une erreur est survenue avec Resamania2",
	},
	sign : {
		title: 'Signature du contrat',
		explain: "Entrer ici le code reçu par SMS pour signer le contrat",
		placeholder: "Code reçu par SMS",
		resendCodeLabel: "Renvoyer le code",
		badCodeLabel: "Code incorrect",
		signLabel: "Valider la signature",
	},
	payment: {
		loadingLabel: "Vérification en cours",
		informationLabel: "Informations de paiement",
		resumeLabel: "Total à payer par CB: ",
		payLabel: "Payer ",
		errorLabel: "Nous avons un soucis avec votre paiment.",
		errorTitle: "Une erreur est survenue",
	},
	loadingDefault: "Chargement en cours",
	loadingSmsCodeCheck: 'Vérification du code',
	loadingSmsCodeSend: 'Envoi du SMS',
	loadingTextUserCreation: "Création de votre compte",
	loadingTextCartCreation: "Création de votre panier",
	loadingTextAddressCheck: "Vérification de votre adresse",
	loadingTextOffersLoad: "Chargement des offres",
	plans: {
		title: "Choisissez votre formule",
		incitation: "Sélectionnez une formule",
		feesLabel: "Frais d'inscription",
		nofeesLabel: "Aucun frais d'inscription",
		nextLabel: "Suivant",
		noPlanFoundTitle: "Aucune formule",
		noPlanFoundTexts : [
			"Nous n'avons trouvé aucune formule pour ce club,",
			"veuillez réessayer plus tard ou changer de club via l'étape 1"
		],
		useImg : 'withText',
		imageBaseURL: 'https://api.resamania.com',
		desktopInlineBy3: true,
	},
	productsLoader : {
		contextFilter: 'member.shop',
		errorTitle: "Une erreur est survenue avec Resamania2",
		errorText: 'Veuillez réessayer plus tard ou avec un autre club',
	},
	options: {
		mandatoriesAreExcludedFromFreeChoice: true,
		title: "Choisissez vos options",
		useImg : 'withText',
		imageBaseURL: 'https://api.resamania.com',
		message: "Un doute sur le choix de tes options ? Rien de grave, tu pourras avoir des infos ultérieurement directement à l’accueil de ton club.",
		nextLabel: "Suivant",
		desktopInlineBy3: true,
	},
	regulars : {
		title: "Boutique",
		nextLabel: "Suivant",
		useImg : 'withText',
		imageBaseURL: 'https://api.resamania.com',
		message: "Les produits que tu auras choisis seront disponible à l’accueil de ton club lorsque tu viendras pour ta première séance !"
	},
	recap: {
		title: "Récapitulatif",
		myOffer: "Mon offre",
		myOption: "Mes options",
		myCounter: "Mes achats",
		nextLabel: "Je confirme",
		payByCardLabel: "Total à payer par carte",
		payBySepaLabel : "Total prélevé directement",
	},
	sepa : {
		title: "RIB - Paiement Mensuel",
		validateLabel : "J’accepte par la présente les prélèvements automatiques SEPA",
		explain: "Veuillez mettre votre RIB ci-dessous pour valider votre abonnement et signer votre mandat de prélèvement SEPA",
		ownerLabel: "Titulaire du compte",
		ibanLabel: "IBAN",
		bicLabel: "BIC",
		bankLabel: "Nom de la banque",
	},
	contract: {
		title: "Votre contrat",
		signLabel: "Signer le contrat",
	},
	confirmation: {
		welcomeLabel: "Bienvenue chez",
		text: "Votre adhésion a bien été prise en compte, vous recevrez un e-mail de confirmation sous peu.",
		backLabel: "Retour au site.",
		tracker: (tunnel) => !!tunnel && <img src={`https://sekoya.go2cloud.org/aff_goal?a=l&goal_name=cpa&adv_id=527&offer_id=173&adv_sub=${tunnel.trackerUid}&conversion_unique_id=173-${tunnel.trackerUid}&amount=${tunnel?.sales?.totalTI || 0}`} width="1" height="1" />,
	},
	steps: {
		display: {
			showShortNames: false
		},
		clubSelect: {
			title: 'VOTRE CLUB',
			withMap: true,
			searchable:true,
			showMap: "Masquer la carte",
			hideMap: "Voir la carte",
			nextLabel: "Suivant",
			showOnly: 3,
			confirm: {
				txt: "En cochant cette case, je confirme souhaiter m’inscrire au club sélectionné, comme étant mon club d’entrainement principal."
			},
		},
		userForm: {
			displayFirstOffer: false,
			nextLabel: "Suivant",
			genderLabel: 'Civilité',
			gender_F: "Mme",
			gender_M: "M.",
			firstnameLabel: 'Prénom',
			lastnameLabel: "Nom",
			emailLabel: 'Adresse e-mail',
			phoneLabel: "Nº de téléphone portable",
			phoneExplain: "Utilisé pour la signature du contrat et du mandat SEPA, utilisez le format international",
			birthdateLabel: "Date de naissance",
			addressLabel: "Adresse",
			backLabel: "Retour",
			optin: {
				text: 'J’accepte qu’ON AIR utilise mes informations pour améliorer mon expérience d’entraînement, m’envoyer des messages de motivation et m’informer de la vie du club.',
				href: 'https://onair-fitness.fr/politique-de-confidentialite/',
				moreLabel: "En savoir plus",
				step: 2,
			},
			minAge: {
				value: 18,
				text: "Vous avez moins de 18 ans, nous vous invitions à vous rapprocher directement du club pour votre inscription."
			}
		},
	},
	rootProperties: {
		'primary': '#AE1D22',
		'primary--dark': 'darken(#AE1D22, 15)',
		'placeholder': '#999999',
		'border': '#3d3d3d',
	},
	htmlStyle: `
		html {
			font-weight: 400;
			font-family: 'Montserrat', sans-serif;
		}
	`,
	theme: {
		primary: '#AE1D22',
		fontFamily: "Montserrat, sans-serif",
		buttonPrimary: {
			color: '#AE1D22',
			text: '#fff',
		},
		buttonOther: {
			color: 'black',
			text: '#fff',
		},
		buttonSecondary: {
			color: "black",
			text: '#fff'
		},
		stepHeader: {
			fontWeight: 800,
			fontSize: "1.25rem",
			textTransform: 'uppercase',
			paddingTop:'1rem'
		}
	}
};

